import Min2Hour from './Min2Hour'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Min2Hour />
      </header>
    </div>
  );
}

export default App;
